// @ts-check
import React, { useLayoutEffect, useRef, useState } from 'react'
import { clear, drawLine } from 'utils/canvas'
import { H3, Paragraph } from 'components/typography'
import ClassroomToolbar, {
  brushColors,
  classroomToolbarIds
} from 'components/academy/classroom/Toolbar'
import Modal from './Modal'
import styles from './DrawingModal.module.css'

const MIN_WIDTH = 150
const MIN_HEIGHT = 150
const stateByTool = {
  [classroomToolbarIds.draw]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.line]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}
const throttle = (callback, delay) => {
  let previousCall = new Date().getTime()
  return function () {
    const time = new Date().getTime()
    if (time - previousCall >= delay) {
      previousCall = time
      callback.apply(null, arguments)
    }
  }
}
function DrawingModal({ onCancel, onOk }) {
  const [isDrawing, setIsDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [paths, setPaths] = useState([])
  const [currentPath, setCurrentPath] = useState([])
  const [canvasSize, setCanvasSize] = useState({
    width: 650,
    height: 350
  })
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [toolbarId, setToolbarId] = useState(classroomToolbarIds.draw)
  const canvasRef = useRef(null)
  const containerRef = useRef(null)

  const handleOk = () => onOk(makeDataURL(canvasRef))

  const redrawCanvas = (customPaths = paths) => {
    const ctx = canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

    customPaths.forEach(path => {
      if (path.length === 0) return
      ctx.beginPath()
      path.forEach((point, index) => {
        ctx.globalCompositeOperation = point.operation
        if (point.color === 'transparent')
          ctx.globalCompositeOperation = 'destination-out'
        else {
          ctx.globalCompositeOperation = 'source-over'
          ctx.strokeStyle = point.color
        }
        ctx.lineWidth = point.lineWidth
        ctx.lineCap = 'round'
        if (index === 0) {
          ctx.moveTo(point.x, point.y)
        } else {
          ctx.lineTo(point.x, point.y)
        }
      })
      ctx.stroke()
    })
    ctx.globalCompositeOperation = 'source-over'
  }

  const handleMouseDown = e => {
    const { clientY, clientX } = e
    setIsDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    const startPoint = {
      x: endX,
      y: endY,
      color: tool.color,
      lineWidth: tool.lineWidth,
      operation:
        tool.color === 'transparent' ? 'destination-out' : 'source-over'
    }
    setPosition(startPoint)
    setCurrentPath([startPoint])
  }

  const handleMouseMove = e => {
    if (!isDrawing) return
    const { clientX, clientY } = e
    const { x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    const newPoint = {
      x: endX,
      y: endY,
      color: tool.color,
      lineWidth: tool.lineWidth,
      operation:
        tool.color === 'transparent' ? 'destination-out' : 'source-over'
    }
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    setPosition(newPoint)
    setCurrentPath(prevPath => [...prevPath, newPoint])
  }

  const handleMouseLeave = e => {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    setPaths(prevPaths => [...prevPaths, currentPath])
    setCurrentPath([])
    setIsDrawing(false)
  }

  const handleTouchDown = e => {
    if (e.touches.length != 1) return e
    const { clientY, clientX } = e.touches[0]
    setIsDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    const startPoint = {
      x: endX,
      y: endY,
      color: tool.color,
      lineWidth: tool.lineWidth,
      operation:
        tool.color === 'transparent' ? 'destination-out' : 'source-over'
    }
    setPosition(startPoint)
    setCurrentPath([startPoint])
  }
  const handleTouchMove = e => {
    if (e.touches.length != 1) return e
    if (!isDrawing) return
    const { clientY, clientX } = e.touches[0]
    const { x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    const newPoint = {
      x: endX,
      y: endY,
      color: tool.color,
      lineWidth: tool.lineWidth,
      operation:
        tool.color === 'transparent' ? 'destination-out' : 'source-over'
    }
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    setPosition(newPoint)
    setCurrentPath(prevPath => [...prevPath, newPoint])
  }

  const handleTouchLeave = () => {
    if (!isDrawing) return
    setPaths(prevPaths => [...prevPaths, currentPath])
    setCurrentPath([])
    setIsDrawing(false)
  }

  const startResizing = e => {
    const { clientY, clientX } = e
    setIsResizing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }

  const stopResizing = e => {
    const { clientY, clientX } = e
    setIsResizing(false)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }

  const handleResizing = e => {
    if (!isResizing || e.target !== containerRef.current) return

    const { clientX, clientY } = e
    const { x, y } = containerRef.current.getBoundingClientRect()

    const newWidth = Math.max(MIN_WIDTH, clientX - x)
    const newHeight = Math.max(MIN_HEIGHT, clientY - y)

    const scaleX = newWidth / canvasSize.width
    const scaleY = newHeight / canvasSize.height
    const scaleFactor = (scaleX + scaleY) / 2

    const scaledPaths = paths.map(path =>
      path.map(point => ({
        x: point.x * scaleX,
        y: point.y * scaleY,
        color: point.color,
        lineWidth: point.lineWidth * scaleFactor,
        operation: point.operation
      }))
    )

    containerRef.current.style.width = `${newWidth}px`
    containerRef.current.style.height = `${newHeight}px`
    canvasRef.current.width = newWidth
    canvasRef.current.height = newHeight

    setCanvasSize({ width: newWidth, height: newHeight })
    setPaths(scaledPaths)
    redrawCanvas(scaledPaths)
  }

  const handleEndResize = e => {
    stopResizing(e)
    handleResizing(e)
  }

  const handleClickToolbar = id => {
    if (id === classroomToolbarIds.eraseAll) {
      setPaths([])
      clear(canvasRef)
      return
    }
    setTool(stateByTool[id])
    setToolbarId(id)
  }

  const handleSetBrushColor = color => setTool(state => ({ ...state, color }))

  useLayoutEffect(() => {
    const cancelCanvasTouch = e =>
      e.target == canvasRef.current &&
      e.touches.length === 1 &&
      e.preventDefault()

    document.body.addEventListener('touchstart', cancelCanvasTouch)
    document.body.addEventListener('touchend', cancelCanvasTouch)
    document.body.addEventListener('touchmove', cancelCanvasTouch, {
      passive: false
    })
    return () => {
      document.body.removeEventListener('touchstart', cancelCanvasTouch)
      document.body.removeEventListener('touchend', cancelCanvasTouch)
      document.body.removeEventListener('touchmove', cancelCanvasTouch)
    }
  }, [])
  useLayoutEffect(() => {
    if (isCanvasReady) return
    const { width, height } = canvasRef.current.getBoundingClientRect()
    canvasRef.current.width = width
    canvasRef.current.height = height
    setIsCanvasReady(true)
  }, [isCanvasReady])

  return (
    <Modal onCancel={onCancel} onOk={handleOk} showCloseIcon>
      <div className={styles.modal}>
        <H3 className={styles.header}>Mejor con un dibujo 🖌</H3>
        <div
          ref={containerRef}
          className={styles.resizableBox}
          style={{
            width: canvasSize.width,
            height: canvasSize.height
          }}
          onClick={startResizing}
          onMouseDown={startResizing}
          onMouseUp={handleEndResize}
          onTouchStart={startResizing}
          onTouchEnd={e => handleEndResize(e.changedTouches[0])}
        >
          <canvas
            ref={canvasRef}
            className={styles.canvas}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseLeave}
            onMouseOut={handleMouseLeave}
            onMouseMove={throttle(handleMouseMove, 10)}
            onTouchStart={handleTouchDown}
            onTouchEnd={handleTouchLeave}
            onTouchCancel={handleTouchLeave}
            onTouchMove={throttle(handleTouchMove, 10)}
          />
        </div>
        <div className={styles.toolbar}>
          <ClassroomToolbar
            selectedOption={toolbarId}
            optionIdsToHide={[
              classroomToolbarIds.type,
              classroomToolbarIds.line
            ]}
            brushColor={tool.color}
            onClick={handleClickToolbar}
            onSetColor={handleSetBrushColor}
          />
          <Paragraph type='body2Bold'>
            📏 {(canvasSize.width || 0).toFixed(0)} x{' '}
            {(canvasSize.height || 0).toFixed(0)}
          </Paragraph>
        </div>
      </div>
    </Modal>
  )
}

export default DrawingModal

const makeDataURL = (
  canvasRef,
  format = 'jpeg',
  quality = 0.85,
  borderSize = 1,
  borderColor = '#34e9aa'
) => {
  const fauxCanvas = document.createElement('canvas')
  fauxCanvas.width = canvasRef.current.width + borderSize * 2
  fauxCanvas.height = canvasRef.current.height + borderSize * 2

  const fauxContext = fauxCanvas.getContext('2d')

  fauxContext.fillStyle = '#fff'
  fauxContext.fillRect(0, 0, fauxCanvas.width, fauxCanvas.height)

  fauxContext.strokeStyle = borderColor
  fauxContext.lineWidth = borderSize
  fauxContext.strokeRect(0, 0, fauxCanvas.width, fauxCanvas.height)

  fauxContext.drawImage(canvasRef.current, borderSize, borderSize)

  return fauxCanvas.toDataURL(`image/${format}`, quality)
}
