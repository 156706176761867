//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import * as Y from 'yjs'
import { QuillBinding } from 'y-quill'
import { io } from 'socket.io-client'
import { useNotificationActions } from 'context/NotificationProvider'
import QuillEditor from 'components/textEditor/QuillEditor'
import DrawingModal from 'components/modals/DrawingModal'
import { Paragraph } from 'components/typography'
import config from 'config'

const MAX_SIZE = 2 * 1024 * 1024 // 2MB

function getSizeInBytes(str) {
  return new Blob([str]).size
}

function Editor({}) {
  const quillRef = useRef(null)
  const [showCanvas, setShowCanvas] = useState(false)
  const [contentSize, setContentSize] = useState(0)
  const { setErrorMessage, setWarningMessage } = useNotificationActions()

  const handleAddImage = dataURL => {
    if (!quillRef.current) return
    const quill = quillRef.current
    quill.focus()
    const range = quill.getSelection() || { index: quill.getLength() }
    quill.insertEmbed(range.index, 'image', dataURL)
    setShowCanvas(false)
  }

  // useEffect(() => {
  //   const fetchDocument = async () => {
  //     try {
  //       const response = await fetch(
  //         'http://localhost:5000/api/health/file/1-JThSipSVds1hWgniJiOBxBYsZHMA7KhXN872XGsWNI'
  //       )
  //       const data = await response.json()

  //       console.log('Delta recibido:', data.delta) // Verificar que el Delta sea válido

  //       if (data.delta && data.delta.ops) {
  //         quillRef.current.setContents(data.delta)
  //       } else {
  //         console.error('Formato de Delta inválido:', data.delta)
  //       }
  //     } catch (error) {
  //       console.error('Error:', error)
  //     }
  //   }

  //   fetchDocument()
  // }, [])

  useEffect(() => {
    if (!quillRef.current) return
    const quill = quillRef.current
    const socket = io(config.apiURL)
    socket.on('connect_error', e => {
      setErrorMessage({ message: e.message })
    })

    const ydoc = new Y.Doc()
    const ytext = ydoc.getText('quill')

    new QuillBinding(ytext, quill)
    quill.on('text-change', function () {
      const delta = quill.getContents()
      const jsonString = JSON.stringify(delta)
      const contentSize = getSizeInBytes(jsonString)
      setContentSize(contentSize / (1024 * 1024))

      if (contentSize > MAX_SIZE)
        setWarningMessage({
          message: 'Has alcanzado los 2MB. Tu archivo empieza a pesar demasiado'
        })
    })
    socket.emit('get-document', 'documento1')
    socket.on('document-update', update => {
      try {
        const uint8Array = new Uint8Array(update)
        Y.applyUpdate(ydoc, uint8Array)
      } catch (err) {
        setErrorMessage({ message: err.message })
      }
    })

    ydoc.on('update', update => {
      socket.emit('update-document', update)
    })

    return () => {
      quillRef.current = null
      socket.off('document-update')
      socket.disconnect()
    }
  }, [setErrorMessage, setWarningMessage])

  useEffect(() => {
    if (!quillRef.current) return
    const quill = quillRef.current
    const toolbar = quill.getModule('toolbar')
    toolbar.addHandler('draw', () => setShowCanvas(true))
  }, [])
  return (
    <div>
      <div
        style={{
          height: 'calc(100vh - 120px)',
          padding: '0 0 150px 0', //TODO: Calcular a mano según pantalla
          marginBottom: 5
          // border: '1px solid red'
        }}
      >
        <QuillEditor
          ref={quillRef}
          theme='snow'
          placeholder='¡Apunta tus ideas aquí!'
        />
      </div>

      {showCanvas && (
        <DrawingModal
          onCancel={() => setShowCanvas(false)}
          onOk={handleAddImage}
        />
      )}
      <Paragraph>El archivo pesa {contentSize?.toFixed(2)} MB</Paragraph>
      {/* <button
        onClick={() => {
          if (!quillRef.current) return
          const delta = quillRef.current.getContents()
          const jsonString = JSON.stringify(delta)

          navigator.clipboard.writeText(jsonString).catch(err => {
            console.error('Error al copiar: ', err)
          })
        }}
      >
        COPIAR JSON
      </button> */}
    </div>
  )
}

export default Editor
