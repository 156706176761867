import React from 'react'
import styles from './Tooltip.module.css'

const Tooltip = ({ className = '', label, children }) => {
  return (
    <div className={[styles.tooltip, className].join(' ')}>
      {children}
      <div className={styles['tooltip-text']}>{label}</div>
    </div>
  )
}

export default Tooltip
