//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import * as PdfJsLib from 'pdfjs-dist/legacy/build/pdf.js'
import PdfJsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import {
  sendNotebookBlackboardLine,
  sendNotebookPdfLine,
  sendNotebookChange,
  sendNotebookChangePage,
  sendNotebookSyncPage,
  sendNotebookClear,
  sendNotebookWhiteboardType
} from 'api/sockets'
import { uploadPDF } from 'api/files'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import Notebook from 'assets/icons/Notebook'
import Eye from 'assets/icons/Eye'
import Marker from 'assets/icons/Marker'
import Sync from 'assets/icons/Sync'
import { useNotificationActions } from 'context/NotificationProvider'
import DownloadButton from 'components/buttons/Download'
import Button from 'components/buttons/Button'
import NotebookSelect from 'components/selects/NotebookSelect'
import TemporaryNotebookSelect from 'components/selects/TemporaryNotebookSelect'
import Modal from 'components/modals/Modal'
import { H2, Paragraph } from 'components/typography'
import HangingUp from 'components/buttons/HangingUp'
import ClassroomToolbar, { brushColors, classroomToolbarIds } from '../Toolbar'
import { classroomTabIds } from './Tabs'
import StraightLinePreview from './common/StraightLinePreview'
import useStraightLine from './common/hooks/useStraightLine'
import BackgroundToolbar from './common/BackgroundToolbar'
import useBackground from './common/hooks/useBackground'
import styles from './Notebook.module.css'

const stateByTool = {
  [classroomToolbarIds.draw]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.line]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}

function ClassroomNotebook({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  student,
  canvasContext,
  selectedNotebookCategory,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange,
  onHangUp,
  onChangeSelectedCategory
}) {
  const [file, setFile] = useState('')
  const [notebookPdf, setPdf] = useState({ source: null, page: 1 })
  const [pdfIsRendering, setPdfIsRendering] = useState(false)
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [studentActivePage, setStudentActivePage] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [isDrawing, setDrawing] = useState(false)
  const [isPdfDrawing, setPdfDrawing] = useState(false)
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [pdfPosition, setPdfPosition] = useState({ x: 0, y: 0 })
  const [isMounted, setIsMounted] = useState(false)
  const [showPdfModal, setShowModal] = useState(false)
  const [showSelectors, setShowSelectors] = useState(true)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const { setErrorMessage } = useNotificationActions()
  const notebookCanvasRef = useRef(null)
  const canvasRef = useRef(null)

  const handlePDFChange = useCallback(
    (url, category, title) => {
      sendNotebookChange({ socket, student, url, title }).catch(e =>
        console.error('Error sending notebook url to student')
      )
      setShowSelectors(false)
      setFile(url)
      onChangeSelectedCategory(category)
    },
    [onChangeSelectedCategory, socket, student]
  )

  const handleTemporaryPDFChange = useCallback(
    (url, title) => {
      sendNotebookChange({ socket, student, url, title, tmp: true }).catch(e =>
        console.error('Error sending notebook url to student')
      )
      setShowSelectors(false)
      setFile(url)
    },
    [socket, student]
  )
  const handlePageChange = newPage => {
    const pages = notebookPdf.source && notebookPdf.source.numPages
    if (!pages) return
    const page = Math.min(Math.max(1, notebookPdf.page + newPage), pages)
    setPdf(current => ({ ...current, page }))
  }
  const handleForcePageSync = () => {
    if (!notebookPdf.source) return
    sendNotebookSyncPage({ socket, student, page: notebookPdf.page })
      .then(() => {
        setStudentActivePage(notebookPdf.page)
        handlePageChange(0)
      })
      .catch(_ => null)
  }

  const renderDocument = useCallback(() => {
    if (!notebookPdf.source || !isCanvasReady) return
    setPdfIsRendering(true)
    clear(notebookCanvasRef)
    notebookPdf.source
      .getPage(notebookPdf.page)
      .then(page => {
        const defaultViewport = page.getViewport({ scale: 1 })

        const blackboard = notebookCanvasRef.current
        const canvasContext = blackboard.getContext('2d', {
          willReadFrequently: true
        })

        const { devicePixelRatio = 1 } = window
        const dpr = Math.min(2, devicePixelRatio)
        const widthRatio = blackboard.clientWidth / defaultViewport.width
        const heightRatio = blackboard.clientHeight / defaultViewport.height
        const scale = Math.max(widthRatio, heightRatio)
        const viewport = page.getViewport({
          scale
        })

        notebookCanvasRef.current.width =
          notebookCanvasRef.current.getBoundingClientRect().width * dpr
        notebookCanvasRef.current.height =
          notebookCanvasRef.current.getBoundingClientRect().height * dpr

        canvasContext.scale(dpr, dpr)

        const render = page.render({
          canvasContext,
          viewport,
          transform: [
            blackboard.clientWidth / viewport.width,
            0,
            0,
            blackboard.clientHeight / viewport.height,
            0,
            0
          ]
        })
        render.promise.then(() => {
          setPdfIsRendering(false)
          sendNotebookChangePage({
            socket,
            student,
            page: notebookPdf.page
          }).catch(_ => null)
        })
      })
      .catch(e => console.error('Error rendering pdf: ', e))
      .finally(() => {
        setPdfIsRendering(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanvasReady, notebookPdf, socket, student])

  const handleSaveContext = useCallback(
    () =>
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.notebook
      ),

    [onSaveCanvasContext]
  )
  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])
  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.notebook)
    clear(canvasRef)
    renderDocument()
  }, [onClearCanvasContext, renderDocument])

  const {
    isDrawingStraightLine: isDrawingStraightLinePdf,
    firstLinePoint: firstLinePointPdf,
    lineStylePreview: lineStylePreviewPdf,
    handleDrawStraightLine: handleDrawStraightLinePdf,
    handleShowStraightLinePreview: handleShowStraightLinePreviewPdf,
    resetStraightLineState: resetStraightLineStatePdf
  } = useStraightLine({
    selectedTool,
    canvasRef: notebookCanvasRef,
    tool,
    socket,
    student,
    handleSaveContext,
    sendWhiteboardLine: sendNotebookPdfLine
  })
  const {
    isDrawingStraightLine,
    firstLinePoint,
    lineStylePreview,
    handleDrawStraightLine,
    handleShowStraightLinePreview,
    resetStraightLineState
  } = useStraightLine({
    selectedTool,
    canvasRef,
    tool,
    socket,
    student,
    handleSaveContext,
    sendWhiteboardLine: sendNotebookBlackboardLine
  })
  const { backgroundType, bgdStyle, setBackgroundType } = useBackground({
    socket,
    student,
    canvas: canvasRef,
    sendWhiteboardType: sendNotebookWhiteboardType
  })
  const handleClearBoard = useCallback(() => {
    if (!student) return
    sendNotebookClear({ socket, student }).catch(_ => null)
    handleClearCanvasContext()
  }, [handleClearCanvasContext, socket, student])

  const handleClickToolbar = id => {
    if (id === classroomToolbarIds.eraseAll) return handleClearBoard()
    if (isDrawingStraightLinePdf) {
      resetStraightLineStatePdf()
    }
    if (isDrawingStraightLine) {
      resetStraightLineState()
    }

    setTool(stateByTool[id])
    onToolChange(id)
  }
  const handleSetBrushColor = color => setTool(state => ({ ...state, color }))
  const handleKeyDown = e => {
    e.preventDefault()
    if (e.key === 'Escape') {
      resetStraightLineStatePdf()
      resetStraightLineState()
    }
  }
  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleMouseDownPdf = e => {
    if (!student || !notebookPdf.source || isDrawingStraightLinePdf) return
    const { clientY, clientX } = e
    setPdfDrawing(true)
    const { x, y } = notebookCanvasRef.current.getBoundingClientRect()
    setPdfPosition({ x: clientX - x, y: clientY - y })
  }

  const handleMouseMovePdf = e => {
    if (!student || !notebookPdf.source) return
    if (isDrawingStraightLinePdf) return handleShowStraightLinePreviewPdf(e)
    if (!isPdfDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } =
      notebookCanvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      notebookCanvasRef,
      pdfPosition.x,
      pdfPosition.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendNotebookPdfLine({
      socket,
      student,
      x0: pdfPosition.x / width,
      y0: pdfPosition.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    }).catch(_ => null)
    setPdfPosition({ x: endX, y: endY })
  }

  const handleMouseLeavePdf = e => {
    if (!isPdfDrawing || !student || !notebookPdf.source) return
    const { width, height, x, y } =
      notebookCanvasRef.current.getBoundingClientRect()
    const endX = e.clientX - x
    const endY = e.clientY - y
    drawLine(
      notebookCanvasRef,
      pdfPosition.x,
      pdfPosition.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendNotebookPdfLine({
      socket,
      student,
      x0: pdfPosition.x / width,
      y0: pdfPosition.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    }).catch(_ => null)
    setPdfDrawing(false)
  }
  const handleMouseDown = e => {
    if (!student || !notebookPdf.source || isDrawingStraightLine) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }

  const handleMouseMove = e => {
    if (!student || !notebookPdf.source) return
    if (isDrawingStraightLine) return handleShowStraightLinePreview(e)
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendNotebookBlackboardLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    }).catch(_ => null)
    setPosition({ x: endX, y: endY })
  }

  const handleMouseLeave = e => {
    if (!isDrawing || !student || !notebookPdf.source) return
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = e.clientX - x
    const endY = e.clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendNotebookBlackboardLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    }).catch(_ => null)
    setDrawing(false)
  }

  const handleDragOver = e => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragEnter = e => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDrop = e => {
    e.preventDefault()
    setDragging(false)
    const file = e.dataTransfer.files[0]
    if (!file)
      return setErrorMessage({
        message: 'No se ha detectado ningún archivo válido'
      })
    setIsUploadingFile(true)
    uploadPDF({ file, studentId: student })
      .then(url => {
        sendNotebookChange({
          socket,
          student,
          url,
          title: file.name,
          tmp: true
        }).catch(_ => null)
        setShowSelectors(false)
        setFile(url)
      })
      .catch(e => setErrorMessage({ message: e.message }))
      .finally(() => setIsUploadingFile(false))
  }

  useEffect(() => {
    if (!socket) return
    if (!canvasRef || !canvasRef.current) return
    socket.on(
      'classroom:students:notebook-page-change',
      ({ studentId, page }) => {
        if (studentId === student) setStudentActivePage(page)
      }
    )
    return () => {
      if (!socket || !isActive) return
      socket.off('classroom:students:notebook-page-change')
    }
  }, [socket, student, isActive])
  useLayoutEffect(() => {
    if (pdfIsRendering || isCanvasReady || !isActive) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    notebookCanvasRef.current.width =
      notebookCanvasRef.current.getBoundingClientRect().width
    notebookCanvasRef.current.height =
      notebookCanvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
    setIsResizing(false)
  }, [isActive, isCanvasReady, pdfIsRendering])

  useLayoutEffect(() => {
    const resize = () => {
      setIsResizing(true)
      setIsCanvasReady(false)
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  useLayoutEffect(() => {
    if (isVisible && !isResizing) {
      const needResize =
        canvasRef.current.width !=
          canvasRef.current.getBoundingClientRect().width ||
        canvasRef.current.height !=
          canvasRef.current.getBoundingClientRect().height
      if (needResize) {
        canvasRef.current.width =
          canvasRef.current.getBoundingClientRect().width
        canvasRef.current.height =
          canvasRef.current.getBoundingClientRect().height
      }
      handleRestoreCanvasContext()
    }
  }, [
    handleRestoreCanvasContext,
    handleSaveContext,
    isResizing,
    isVisible,
    renderDocument
  ])

  useLayoutEffect(() => {
    if (isDrawing) return
    handleSaveContext()
  }, [handleSaveContext, isDrawing])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    // @ts-ignore
    PdfJsLib.GlobalWorkerOptions.workerSrc = PdfJsWorker
  }, [])
  useEffect(() => {
    if (!file) return
    PdfJsLib.getDocument(file)
      .promise.then(source => setPdf({ source, page: 1 }))
      .catch(() => {
        setPdf({ source: null, page: 1 })
        clear(canvasRef)
      })
  }, [file])

  useEffect(() => {
    renderDocument()
  }, [renderDocument])

  return (
    <div
      className={styles.blackboard}
      hidden={!isVisible && isMounted}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div
        className={[
          styles.innerContainer,
          styles.dragAndDropArea,
          dragging ? styles.dropLabel : '',
          isUploadingFile ? styles.uploadingLabel : ''
        ].join(' ')}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className={styles.notebookArea}>
          <div
            className={styles.notebookActions}
            onClick={() => handlePageChange(-1)}
          >
            <ChevronLeft className={styles.notebookChevrons} />
          </div>
          <div className={styles.notebookCanvasArea}>
            <canvas
              ref={notebookCanvasRef}
              className={[
                styles.notebookCanvas,
                !notebookPdf.source ? styles.notebookCanvasDisabled : ''
              ].join(' ')}
              onDoubleClick={() => notebookPdf.source && setShowModal(true)}
              onMouseUp={handleMouseLeavePdf}
              onMouseOut={handleMouseLeavePdf}
              onMouseDown={handleMouseDownPdf}
              onMouseMove={throttle(handleMouseMovePdf, 10)}
              onClick={handleDrawStraightLinePdf}
            />
            <StraightLinePreview
              firstLinePoint={firstLinePointPdf}
              lineStylePreview={lineStylePreviewPdf}
            />
          </div>

          <div
            className={styles.notebookActions}
            onClick={() => handlePageChange(1)}
          >
            <ChevronRight className={styles.notebookChevrons} />
          </div>
        </div>

        {showSelectors && isVisible && (
          <div className={styles.notebookSelects}>
            <TemporaryNotebookSelect onSelect={handleTemporaryPDFChange} />
            <NotebookSelect
              selectedNotebookCategory={selectedNotebookCategory}
              onSelect={handlePDFChange}
            />
          </div>
        )}
        <div className={styles.canvasArea} hidden={showSelectors}>
          <canvas
            ref={canvasRef}
            className={styles.canvas}
            style={bgdStyle}
            onMouseUp={handleMouseLeave}
            onMouseOut={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseMove={throttle(handleMouseMove, 10)}
            onClick={handleDrawStraightLine}
          />
          <StraightLinePreview
            firstLinePoint={firstLinePoint}
            lineStylePreview={lineStylePreview}
          />
        </div>
      </div>
      <div className={styles.tools}>
        <div className={styles.left}>
          <ClassroomToolbar
            selectedOption={selectedTool}
            optionIdsToHide={[classroomToolbarIds.type]}
            brushColor={tool.color}
            onClick={handleClickToolbar}
            onSetColor={handleSetBrushColor}
          />
          <BackgroundToolbar
            // @ts-ignore
            selectedOption={backgroundType}
            onSelect={setBackgroundType}
          />
        </div>

        <HangingUp onHangUp={onHangUp} />
        <div className={styles.right}>
          <div className={styles.innerRight}>
            <div
              className={[
                styles.icons,
                !notebookPdf.source ? styles.statusIconDisabled : ''
              ].join(' ')}
              title='Sincronizar cuadernillo'
            >
              <Sync color={'var(--dark-color)'} onClick={handleForcePageSync} />
            </div>
            <div
              className={[
                styles.icons,
                !notebookPdf.source ? styles.statusIconDisabled : ''
              ].join(' ')}
              title='Estado cuadernillo'
              onClick={() => notebookPdf.source && setShowStatusModal(true)}
            >
              <Eye
                color={
                  studentActivePage !== notebookPdf.page
                    ? undefined
                    : 'var(--seadapted)'
                }
              />
            </div>
            <div className={styles.icons} title='Cambiar cuadernillo'>
              {showSelectors ? (
                <Marker
                  color={'var(--dark-color)'}
                  onClick={() => setShowSelectors(false)}
                />
              ) : (
                <Notebook
                  color='var(--dark-color)'
                  onClick={() => setShowSelectors(true)}
                />
              )}
            </div>
          </div>
          <DownloadButton
            onClick={() => handleDownload(canvasRef, backgroundType)}
            disabled={canvasContext.length === 0}
          />
        </div>
      </div>
      {showPdfModal && (
        <ZoomPage
          source={notebookPdf.source}
          page={notebookPdf.page}
          onClose={() => setShowModal(false)}
        />
      )}
      {showStatusModal && (
        <StatusModal
          teacherPage={notebookPdf.page}
          studentPage={studentActivePage}
          pages={notebookPdf.source && notebookPdf.source.numPages}
          onClose={() => setShowStatusModal(false)}
        />
      )}
    </div>
  )
}

export default ClassroomNotebook

function ZoomPage({ source, page, onClose }) {
  const [zoomToScreen, setZoomToScreen] = useState(false)
  const modalRef = useRef(null)
  const canvasRef = useRef(null)

  const handleKeyDown = useCallback(
    e => {
      e.preventDefault()
      if (e.key === 'Escape') onClose()
    },
    [onClose]
  )

  useLayoutEffect(() => {
    if (!source || !page) return
    source.getPage(page).then(page => {
      const { devicePixelRatio = 1 } = window
      const canvas = canvasRef.current
      canvas.width = canvas.getBoundingClientRect().width
      canvas.height = canvas.getBoundingClientRect().height
      const defaultViewport = page.getViewport({ scale: 1 })
      const canvasContext = canvas.getContext('2d', {
        willReadFrequently: true
      })
      const dpr = Math.min(2, devicePixelRatio)
      const widthRatio = canvas.clientWidth / (defaultViewport.width / dpr)
      const heightRatio = canvas.clientHeight / (defaultViewport.height / dpr)
      const scale = Math.max(widthRatio, heightRatio)

      canvasRef.current.width = Math.floor(defaultViewport.width * scale)
      canvasRef.current.height = Math.floor(defaultViewport.height * scale)
      const viewport = page.getViewport({ scale })
      page.render({ canvasContext, viewport })
    })
  }, [page, source])

  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  return (
    <div
      ref={modalRef}
      className={styles.modal}
      onKeyDown={handleKeyDown}
      tabIndex={100}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalContent}>
          <canvas
            className={[
              styles.zoomPageCanvas,
              zoomToScreen ? styles.adjusted : ''
            ].join(' ')}
            ref={canvasRef}
          />
        </div>
        <div className={styles.modalActions}>
          <Button
            type='secondary'
            size='small'
            label='Cerrar'
            onClick={onClose}
          />
          <Button
            size='small'
            label='Cambiar Zoom'
            onClick={() => setZoomToScreen(v => !v)}
          />
        </div>
      </div>
    </div>
  )
}

function StatusModal({ teacherPage, studentPage, pages, onClose }) {
  return (
    <Modal onCancel={onClose} hideOkButton>
      <div className={styles.statusModalContainer}>
        <H2>Estado del cuadernillo</H2>
        <Paragraph>
          Este cuadernillo tiene <span className={styles.strong}>{pages}</span>{' '}
          páginas.
        </Paragraph>
        {studentPage ? (
          <Paragraph>
            El alumno está en la página{' '}
            <span className={styles.strong}>{studentPage}</span>.
          </Paragraph>
        ) : (
          <Paragraph>El alumno todavía no puede ver el cuadernillo.</Paragraph>
        )}
        <Paragraph>
          Tú estás en la página{' '}
          <span className={styles.strong}>{teacherPage}</span>.
        </Paragraph>
        <Paragraph>
          Las notas se mantienen entre distintas páginas y cuadernillos
        </Paragraph>
      </div>
    </Modal>
  )
}
