//@ts-check
import React, { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom'
import AuthProvider from 'context/AuthProvider'
import NotificationStack from 'components/notifications/NotificationStack'
import Layout from 'components/layout'
import AcademyLayout from 'components/academy/Layout'
import VersionChecker from 'components/notifications/VersionChecker'
import NetworkStatus from 'components/notifications/electronAPI/NetworkStatus'
import SystemStats from 'components/notifications/electronAPI/SystemStats'
import Academy from 'pages/Academy'
import VideoTour from 'pages/VideoTour'
import MediaPermissions from 'pages/MediaPermissions'
import Home from 'pages/Home'
import Login from 'pages/Login'
import UploadPhoto from 'pages/UploadPhoto'
import PersonalData from 'pages/Account/PersonalData'
import Test from 'pages/Test'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route Component={AuthProvider}>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='account' element={<PersonalData />} />
        <Route path='notifications' element={<p>Notifications</p>} />
      </Route>
      <Route path='academy' element={<AcademyLayout />}>
        <Route index element={<Academy />} />
        <Route path='video-tour' element={<VideoTour />} />
        <Route path='media-permissions' element={<MediaPermissions />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/upload-photo/:studentId' element={<UploadPhoto />} />
      <Route path='test' element={<Test />} />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>
  )
)

function App() {
  useEffect(
    () => document.addEventListener('contextmenu', e => e.preventDefault()),
    []
  )

  return (
    <>
      <NotificationStack />

      <RouterProvider router={router} />
      <VersionChecker />

      <SystemStats />
      <NetworkStatus />
    </>
  )
}

export default App
