//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {
  sendWhiteboardClear,
  sendWhiteboardLine,
  sendWhiteboardType
} from 'api/sockets'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import DownloadButton from 'components/buttons/Download'
import HangingUp from 'components/buttons/HangingUp'
import ClassroomToolbar, { brushColors, classroomToolbarIds } from '../Toolbar'
import { classroomTabIds } from './Tabs'
import StraightLinePreview from './common/StraightLinePreview'
import BackgroundToolbar from './common/BackgroundToolbar'
import useStraightLine from './common/hooks/useStraightLine'
import useBackground from './common/hooks/useBackground'
import styles from './Blackboard.module.css'

const stateByTool = {
  [classroomToolbarIds.draw]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.line]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}

function ClassroomBlackboard({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  student,
  canvasContext,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange,
  onHangUp
}) {
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [isDrawing, setDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const canvasRef = useRef(null)

  const handleSaveContext = useCallback(() => {
    canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.blackboard
      )
  }, [onSaveCanvasContext])
  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])
  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.blackboard)
    clear(canvasRef)
  }, [onClearCanvasContext])

  const {
    isDrawingStraightLine,
    firstLinePoint,
    lineStylePreview,
    handleDrawStraightLine,
    handleShowStraightLinePreview,
    resetStraightLineState
  } = useStraightLine({
    selectedTool,
    canvasRef,
    tool,
    socket,
    student,
    handleSaveContext,
    sendWhiteboardLine
  })
  const { backgroundType, bgdStyle, setBackgroundType } = useBackground({
    socket,
    student,
    canvas: canvasRef,
    sendWhiteboardType
  })

  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleMouseDown = e => {
    if (!isActive || isDrawingStraightLine) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleMouseMove = e => {
    if (isDrawingStraightLine) return handleShowStraightLinePreview(e)
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    // @ts-ignore
    sendWhiteboardLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    }).catch(_ => null)
    setPosition({ x: endX, y: endY })
  }
  const handleMouseLeave = e => {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendWhiteboardLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    }).catch(_ => null)
    setDrawing(false)
  }

  const handleTouchDown = e => {
    if (e.touches.length != 1) return e
    if (!isActive) return
    if (isDrawingStraightLine)
      return handleDrawStraightLine({
        ...e,
        clientX: e.touches[0]?.clientX,
        clientY: e.touches[0]?.clientY
      })

    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleTouchMove = e => {
    if (e.touches.length != 1) return e
    if (isDrawingStraightLine)
      return handleShowStraightLinePreview({
        ...e,
        clientX: e.touches[0]?.clientX,
        clientY: e.touches[0]?.clientY
      })
    if (!isDrawing) return
    const { clientY, clientX } = e.touches[0]
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    // @ts-ignore
    sendWhiteboardLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    }).catch(_ => null)
    setPosition({ x: endX, y: endY })
  }

  const handleTouchLeave = () => {
    if (!isDrawing) return
    setDrawing(false)
  }

  const handleClearBoard = () => {
    if (!student) return
    sendWhiteboardClear({ socket, student }).catch(_ => null)
    handleClearCanvasContext()
  }

  const handleClickToolbar = id => {
    if (id === classroomToolbarIds.eraseAll) return handleClearBoard()
    if (isDrawingStraightLine) {
      resetStraightLineState()
    }

    setTool(stateByTool[id])
    onToolChange(id)
  }

  const handleSetBrushColor = color => setTool(state => ({ ...state, color }))
  const handleKeyDown = e => {
    if (e.key === 'Escape') resetStraightLineState()
  }

  useLayoutEffect(() => {
    const cancelCanvasTouch = e =>
      e.target == canvasRef.current &&
      e.touches.length === 1 &&
      e.preventDefault()

    document.body.addEventListener('touchstart', cancelCanvasTouch)
    document.body.addEventListener('touchend', cancelCanvasTouch)
    document.body.addEventListener('touchmove', cancelCanvasTouch, {
      passive: false
    })
    return () => {
      document.body.removeEventListener('touchstart', cancelCanvasTouch)
      document.body.removeEventListener('touchend', cancelCanvasTouch)
      document.body.removeEventListener('touchmove', cancelCanvasTouch)
    }
  }, [])
  useLayoutEffect(() => {
    if (isCanvasReady || !isActive) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
    setIsResizing(false)
  }, [isActive, isCanvasReady])
  useLayoutEffect(() => {
    const resize = () => {
      setIsResizing(true)
      setIsCanvasReady(false)
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])
  useLayoutEffect(() => {
    if (isDrawing) return
    handleSaveContext()
  }, [handleSaveContext, isDrawing])
  useLayoutEffect(() => {
    if (isVisible && !isResizing) {
      const needResize =
        canvasRef.current.width !=
          canvasRef.current.getBoundingClientRect().width ||
        canvasRef.current.height !=
          canvasRef.current.getBoundingClientRect().height
      if (needResize) {
        canvasRef.current.width =
          canvasRef.current.getBoundingClientRect().width
        canvasRef.current.height =
          canvasRef.current.getBoundingClientRect().height
      }
      handleRestoreCanvasContext()
    }
  }, [handleRestoreCanvasContext, isResizing, isVisible])
  useLayoutEffect(() => {
    if (!socket) return
    socket.on(
      'classroom:students:whiteboard-line',
      ({ studentId, x0, y0, x1, y1, color, lineWidth, saveContext }) => {
        if (studentId !== student) return
        const { width, height } = canvasRef.current
        const startX = x0 * width
        const startY = y0 * height
        const endX = x1 * width
        const endY = y1 * height
        drawLine(canvasRef, startX, startY, endX, endY, color, lineWidth)
        saveContext && handleSaveContext()
      }
    )

    return () => {
      if (!socket || !isActive) return
      socket.off('classroom:students:whiteboard-line')
    }
  }, [handleSaveContext, socket, student, isActive])

  useLayoutEffect(() => {
    handleClearCanvasContext()
  }, [handleClearCanvasContext])

  return (
    <div
      className={styles.blackboard}
      hidden={!isVisible}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <canvas
        ref={canvasRef}
        className={styles.canvas}
        style={bgdStyle}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseLeave}
        onMouseOut={handleMouseLeave}
        onMouseMove={throttle(handleMouseMove, 10)}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchLeave}
        onTouchCancel={handleTouchLeave}
        onTouchMove={throttle(handleTouchMove, 10)}
        onClick={handleDrawStraightLine}
      />
      <StraightLinePreview
        firstLinePoint={firstLinePoint}
        lineStylePreview={lineStylePreview}
      />
      <div className={styles.tools}>
        <div className={styles.left}>
          <ClassroomToolbar
            selectedOption={selectedTool}
            optionIdsToHide={[classroomToolbarIds.type]}
            brushColor={tool.color}
            onClick={handleClickToolbar}
            onSetColor={handleSetBrushColor}
          />
          <BackgroundToolbar
            // @ts-ignore
            selectedOption={backgroundType}
            onSelect={setBackgroundType}
          />
        </div>
        <HangingUp onHangUp={onHangUp} />
        <DownloadButton
          onClick={() => handleDownload(canvasRef, backgroundType)}
          disabled={canvasContext.length === 0}
        />
      </div>
    </div>
  )
}

export default ClassroomBlackboard
