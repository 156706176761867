import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import Quill from 'quill'
import Katex from 'katex'
import styles from './QuillEditor.module.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'katex/dist/katex.min.css'

const Inline = Quill.import('blots/inline')

class CustomHandler extends Inline {
  static blotName = 'draw'
}
Quill.register(CustomHandler)
const toolbarOptions = [
  [{ header: 1 }, { header: 2 }, { header: 3 }],
  [{ header: [4, 5, 6, false] }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ font: [] }],

  [{ color: [] }, { background: [] }],

  ['bold', 'italic', 'underline', 'strike'],
  ['clean'],
  ['blockquote', 'code-block'],

  [{ direction: 'rtl' }, { align: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ indent: '-1' }, { indent: '+1' }],

  ['link', 'image', 'video', 'formula'],
  [{ script: 'sub' }, { script: 'super' }],
  ['draw']
]
const QuillEditor = forwardRef(
  (
    {
      readOnly,
      theme,
      placeholder,
      defaultValue,
      onTextChange,
      onSelectionChange
    },
    ref
  ) => {
    const containerRef = useRef(null)
    const defaultValueRef = useRef(defaultValue)
    const onTextChangeRef = useRef(onTextChange)
    const onSelectionChangeRef = useRef(onSelectionChange)

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange
      onSelectionChangeRef.current = onSelectionChange
    })

    useEffect(() => {
      ref.current?.enable(!readOnly)
    }, [ref, readOnly])

    useEffect(() => {
      const container = containerRef.current
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      )
      window.katex = Katex
      const quill = new Quill(editorContainer, {
        theme,
        placeholder,
        modules: {
          toolbar: toolbarOptions
        }
      })

      ref.current = quill

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current)
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args)
      })

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args)
      })
      const customButton = document.querySelector('.ql-draw')
      if (customButton) {
        customButton.innerHTML = `
       <svg width="18" height="18" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.3915 33.3636V68.3985H3V10.007H38.0349M19.3496 42.7062L59.0559 3L68.3985 12.3426L28.6923 52.0489M19.3496 42.7062L14.6783 56.7202L28.6923 52.0489M19.3496 42.7062L28.6923 52.0489M49.7132 12.3426L59.0559 21.6853" stroke="black" stroke-width="4.67132" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `
      }

      return () => {
        ref.current = null
        container.innerHTML = ''
      }
    }, [ref, placeholder, theme])

    return <div ref={containerRef} className={styles.editor} />
  }
)

QuillEditor.displayName = 'QuillEditor'

export default QuillEditor
